import { Stack, Box, Flex, HStack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import logo4p from '../../../assets/4parens-logo.png';
import { ReactComponent as ManageLogo } from '../../../assets/logo4pM.svg';

type Props = {
  leftImage: ReactNode;
  leftTitle: string;
  children: ReactNode;
};

export const PublicRoutesCard = ({ children, leftImage, leftTitle }: Props) => {
  return (
    <HStack
      spacing={0}
      alignItems="center"
      flexDirection={{ base: 'column-reverse', md: 'row' }}
      height="100%"
      maxHeight={{ md: '571px', sm: '100%' }}
    >
      <Box
        minHeight={{ base: '50%', md: '571px' }}
        maxHeight="571px"
        height="100%"
        width={{ base: '100%', md: '50%' }}
        sx={{ borderRadius: { base: '0 0 20px 20px', md: '20px 0 0 20px' } }}
        background="transparent linear-gradient(180deg, #B764DE 0%, #9C4AAD 100%) 0% 0% no-repeat padding-box;"
        display="flex"
        justifyContent="center"
        py="60px"
        minWidth="365px"
      >
        <Flex flexDirection="column" alignItems="center" w="50%">
          <a href="/">
            <ManageLogo />
          </a>
          <Text
            fontSize={{ base: 'xl', md: 'xx-large' }}
            mb={8}
            whiteSpace="nowrap"
            fontWeight={300}
          >
            {leftTitle}
          </Text>
          <Box width={{ base: '200px', md: '299px' }} sx={{ padding: 2 }}>
            {leftImage}
          </Box>
        </Flex>
      </Box>
      <Box
        minHeight={{ base: '50%', md: '571px' }}
        maxHeight="571px"
        height="100%"
        width={{ base: '100%', md: '50%' }}
        sx={{ borderRadius: { base: '20px 20px 0 0', md: '0 20px 20px 0' } }}
        background="white"
        display="flex"
        justifyContent="center"
        py="60px"
        minWidth="365px"
      >
        <Stack
          direction="column"
          alignItems="center"
          spacing={{ base: 10, md: 10 }}
          width={'100%'}
          px={{ base: '32px', md: '64px' }}
        >
          <img src={logo4p} alt="logo-4parents" style={{ width: '220px' }} />
          {children}
        </Stack>
      </Box>
    </HStack>
  );
};
