import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Controller, Control } from 'react-hook-form';
import Select from 'react-select';

export interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  control: Control<any>;
  name: string;
  options: Option[] | [];
  customStyles?: any;
  label: string;
  errors: any;
  emptyStateLabel: string;
  disabled?: boolean;
  selectAll: boolean;
}

const MultiSelectComponent: React.FC<MultiSelectProps> = ({
  control,
  name,
  options,
  customStyles,
  label,
  errors,
  emptyStateLabel,
  disabled,
  selectAll,
  ...rest
}) => {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (selectAll) {
              field.onChange(options);
            } else {
              field.onChange([]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectAll]);
          return (
            <Select
              isDisabled={disabled}
              noOptionsMessage={() => emptyStateLabel}
              placeholder={label}
              {...rest}
              {...field}
              isMulti
              options={options}
              styles={customStyles}
              onChange={(selectedOptions) => field.onChange(selectedOptions)}
              value={field.value}
            />
          );
        }}
      />
      {errors[name] && <p style={{ color: 'red', fontSize: 14 }}> {errors[name]?.message}</p>}
    </Box>
  );
};

export default MultiSelectComponent;
