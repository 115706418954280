import { Stack } from '@chakra-ui/react';
import React from 'react';

import CopyrightInfoBox from '@/components/ui/CopyrightInfoBox/CopyrightInfoBox';

import { ReactComponent as SecurityIlustration } from '../assets/security.svg';
import { PublicRoutesCard } from '../components/PublicRoutesCard';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

export const ResetPasswordPage = () => {
  return (
    <Stack
      width="full"
      justify="center"
      align="center"
      maxWidth="730px"
      height={{ md: '100vh', sm: '100%' }}
      maxHeight={{ md: '571px', sm: '100%' }}
      m={'16px'}
    >
      <PublicRoutesCard
        leftImage={<SecurityIlustration width={'100%'} height={'250'} />}
        leftTitle="Resetowanie hasła"
      >
        <ResetPasswordForm />
      </PublicRoutesCard>

      <CopyrightInfoBox />
    </Stack>
  );
};
