import { Box } from '@chakra-ui/react';

import { createRolesHook } from '@/hooks/useRoles';

import { useGetBoardPostsQuery } from '../api/boardPostsApi';
import BoardPostList from '../components/BoardPostList';

const useRoles = createRolesHook(['user', 'admin']);

const BoardPostsPage = () => {
  const { data } = useGetBoardPostsQuery();
  useRoles();
  return (
    <Box>
      <BoardPostList data={data} />
    </Box>
  );
};

export default BoardPostsPage;
