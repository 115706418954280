import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { authenticationSuccess, useLoginMutation } from '@/features/auth';
import { useAppDispatch } from '@/hooks/store';
import { instance } from '@/lib';

type Form = {
  email: string;
  password: string;
};

const schema = yup.object({
  email: yup.string().required('Pole wymagane').email('Podaj poprawny adres e-mail'),
  password: yup.string().required('Pole jest wymagane'),
});

const LoginForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit: SubmitHandler<Form> = async (data) => {
    try {
      const response = await login(data).unwrap();
      const token = response.tokens.access.token;
      localStorage.setItem('access_token', token);
      dispatch(authenticationSuccess(token));
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <>
      <Stack direction="column" spacing={2} alignItems="center" height={'100%'} width={'100%'}>
        <Text
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight="bold"
          textAlign="center"
          mb={4}
          color="#444444"
          fontFamily="League Spartan"
        >
          Wprowadź swój <br /> adres email i hasło
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', maxWidth: '400px' }}>
          <Stack spacing={4} width="100%" alignItems="center">
            <FormControl isInvalid={!!errors.email}>
              <Input
                borderColor="#444444"
                focusBorderColor="#444444"
                _placeholder={{
                  color: '#444444',
                  fontSize: '16px',
                  fontWeight: 'regular',
                  fontFamily: 'League Spartan',
                }}
                color="black"
                fontFamily="League Spartan"
                placeholder="Adres email"
                type="email"
                {...register('email')}
                autoFocus
                variant="flushed"
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <Input
                  borderColor="#444444"
                  focusBorderColor="#444444"
                  _placeholder={{
                    color: '#444444',
                    fontSize: '16px',
                    fontWeight: 'regular',
                    fontFamily: 'League Spartan',
                  }}
                  fontFamily="League Spartan"
                  color="black"
                  placeholder="Hasło"
                  type={showPassword ? 'text' : 'password'} // Toggle password visibility
                  {...register('password')}
                  variant="flushed"
                />
                <InputRightElement>
                  <Button onClick={handlePasswordToggle} variant="solid" color="black" size="sm">
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              isLoading={isSubmitting}
              colorScheme="purple"
              backgroundColor={'#8D3F98'}
              width={{ base: '100%', sm: 'fit-content' }}
              borderRadius={22}
              py={'12px'}
              px={'32px'}
              fontFamily={'League Spartan'}
              fontSize={16}
            >
              Zaloguj się
            </Button>
          </Stack>
        </form>
        <Box flexGrow={1} />
        <Box
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          width={'100%'}
          justifyContent={'flex-end'}
        >
          <Button
            variant="link"
            onClick={() => navigate('/forgot-password')}
            colorScheme="blackAlpha"
            size="sm"
            fontSize="16px"
            fontFamily={'League Spartan'}
            fontWeight={'normal'}
            color="#444444"
          >
            Zapomniałem hasła
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default LoginForm;
