import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import bgPattern from '../../../assets/bg_pattern.svg';

const Layout = () => {
  return (
    <>
      <Flex
        direction={'column'}
        bgGradient={'linear(to-bl, #8E3F98, #4158D0)'}
        color={'white'}
        position={'relative'}
      >
        <Box
          zIndex={0}
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          background={`url(${bgPattern})`}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          marginX={10}
        />
        <Box zIndex={1}>
          <Outlet />
        </Box>
      </Flex>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Layout;
