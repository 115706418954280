import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Role, useMeQuery } from '@/features/auth';

export const createRolesHook = (roles: Role[]) => {
  return () => {
    const [selectedRoles] = useState(roles);
    const naviagate = useNavigate();
    const { data: user, isSuccess } = useMeQuery();

    useEffect(() => {
      if (isSuccess) {
        if (!selectedRoles.includes(user.user.role)) {
          naviagate('/', {
            replace: true,
          });
        }
      }
    }, [isSuccess, naviagate, selectedRoles, user]);
  };
};
