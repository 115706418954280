import { Box, Button } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

// eslint-disable-next-line no-restricted-imports
import { FormErrorText } from '@/components/form/FormErrorText';

import { useUploadFileMutation } from '../api';
import { FileUploadResponse } from '../types';

import FilePickerWithRef from './FilePickerWithRef';
import Files from './Files';
import PhotoGallery from './PhotoGallery';
import UploadProgress from './UploadProgress';

type CustomFileInputRef = {
  open: () => void;
};

const FormFileHandler = ({
  control,
  name,
  setProcessing,
}: {
  control: Control<any>;
  name: string;
  setProcessing?: (arg0: boolean) => void;
}) => {
  // const { fields, append, remove } = useFieldArray({ control, name });
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const filePickerRef = useRef<CustomFileInputRef>(null);
  const [uploadProgress, setUploadProgress] = useState<Array<any>>([]);

  const [uploadFile] = useUploadFileMutation();

  const handleFileAdded = async (files: Array<any>) => {
    setUploadProgress(files.map((f) => ({ name: f.name, progress: 0 })));
    setProcessing && setProcessing(true);
    const promises = files.map(async (file) => {
      const data = new FormData();
      data.append('file', file, file.name);
      const res = await uploadFile(data).unwrap();
      // append(res);
      field.onChange([...field.value, res]);
      setUploadProgress((prev) => prev.filter((f) => f.name !== file.name));
    });

    await Promise.all(promises);
    setProcessing && setProcessing(false);
  };

  const handleFileRemoved = (id: any) => {
    // const index = fields.findIndex((field) => field.id === x);
    // remove(index);
    field.onChange(field.value.filter((file: FileUploadResponse) => file.id !== id));
  };

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Button
          colorScheme="purple"
          onClick={() => {
            filePickerRef?.current && filePickerRef?.current.open();
          }}
        >
          dodaj obraz lub załącznik
        </Button>
        <FilePickerWithRef ref={filePickerRef} onDrop={handleFileAdded} multiple={true} />
      </Box>
      {invalid && error ? <FormErrorText>{error.message}</FormErrorText> : null}
      <Box style={{ width: '100%' }}>
        {uploadProgress && uploadProgress.length ? <UploadProgress files={uploadProgress} /> : null}
        <PhotoGallery
          photos={(field.value || []).filter((f: any) => f.type === 'image')}
          onRemoveFile={handleFileRemoved}
        />
        <Files
          files={(field.value || []).filter((f: any) => f.type !== 'image')}
          onRemoveFile={handleFileRemoved}
        />
      </Box>
    </>
  );
};

export default FormFileHandler;
