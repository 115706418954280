import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useRef } from 'react';
import { Control, useController } from 'react-hook-form';
import { MdAttachFile, MdDelete } from 'react-icons/md';

import { FormErrorText } from '@/components/form/FormErrorText';

import FilePickerWithRef from './FilePickerWithRef';

type CustomFileInputRef = {
  open: () => void;
};

export const FormFilePicker = ({
  control,
  name,
  label,
}: {
  control: Control<any>;
  name: string;
  label?: string;
}) => {
  const filePickerRef = useRef<CustomFileInputRef>(null);
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const handleFileAdded = (file: File) => {
    console.log('selected File:', file);
    field.onChange(file);
  };

  const handleFileRemoved = () => {
    field.onChange(null);
  };

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Button
          colorScheme="purple"
          onClick={() => {
            filePickerRef?.current && filePickerRef?.current.open();
          }}
        >
          {label ? label : 'dodaj obraz lub załącznik'}
        </Button>
        <FilePickerWithRef ref={filePickerRef} onDrop={handleFileAdded} multiple={false} />
      </Box>
      {invalid && error ? <FormErrorText>{error.message}</FormErrorText> : null}
      {field.value ? (
        <Box style={{ width: '100%' }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 16,
            }}
          >
            <HStack flexGrow={1} alignItems={'center'}>
              <MdAttachFile style={{ fontSize: 24, marginRight: 10 }} />
              <Text>{(field.value as File).name}</Text>
            </HStack>
            <Tooltip label="Usuń załącznik">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleFileRemoved();
                }}
              >
                <MdDelete />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
