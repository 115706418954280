import { Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CopyrightInfoBox from '@/components/ui/CopyrightInfoBox/CopyrightInfoBox';

import { ReactComponent as TeamIlustration } from '../assets/maintanance.svg';
import LoginForm from '../components/LoginForm';
import { PublicRoutesCard } from '../components/PublicRoutesCard';
import { useAuth } from '../hooks/useAuth';

const LoginView = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/app');
    }
  }, [isAuth, navigate]);

  return (
    <Stack
      width="full"
      justify="center"
      align="center"
      maxWidth="730px"
      minHeight={{ md: '100vh', sm: '100%' }}
      maxHeight={{ sm: '100%' }}
    >
      <PublicRoutesCard
        leftImage={<TeamIlustration width="100%" height="100%" />}
        leftTitle="Logowanie"
      >
        <LoginForm />
      </PublicRoutesCard>
      <CopyrightInfoBox />
    </Stack>
  );
};

export default LoginView;
