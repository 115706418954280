import { Button, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormInput from '@/components/form/FormInput';
import { instance } from '@/lib';

type Form = {
  email: string;
};

const INITIAL_VALUES: Form = {
  email: '',
};

const schema = yup.object({
  email: yup.string().email('Niepoprawny format adresu').required('Pole wymagane'),
});

export const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Form>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onSubmit = async (values: Form) => {
    try {
      await instance.post('/auth/forgot-password', {
        email: values.email,
      });
      navigate('/password-reset-email-sent');
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <Stack alignItems={'center'} w={'full'} as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
      <Text
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="bold"
        textAlign="center"
        mb={4}
        color="#444444"
        fontFamily="League Spartan"
      >
        Podaj adres e-mail. Wyślemy na niego informacje potrzebne do zresetowania hasła
      </Text>
      <FormInput
        error={errors.email}
        borderColor="#444444"
        focusBorderColor="#444444"
        _placeholder={{
          color: '#444444',
          fontSize: '16px',
          fontWeight: 'regular',
          fontFamily: 'League Spartan',
        }}
        color="black"
        fontFamily="League Spartan"
        variant="flushed"
        placeholder="Adres email"
        {...register('email')}
      />
      <Button
        backgroundColor={'#8D3F98'}
        width={{ base: '100%', sm: 'fit-content' }}
        borderRadius={22}
        py={'12px'}
        px={'32px'}
        fontFamily={'League Spartan'}
        fontSize={16}
        colorScheme={'purple'}
        type={'submit'}
        isLoading={isSubmitting}
      >
        Wyślij
      </Button>
    </Stack>
  );
};
