import {
  Badge,
  Box,
  Button,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { toast } from 'react-toastify';

import Card from '@/components/ui/Card/Card';
import { Files, PhotoGallery } from '@/features/files';

import { useDeleteBoardPostMutation } from '../api/boardPostsApi';
import { BoardPost } from '../types/BoardPost';

import PostDialog from './PostDialog';
import boardpostbackground from './svg/blob.svg';

const BoardPostList = ({ data }: { data?: Array<BoardPost> }) => {
  const [open, setOpen] = useState(false);
  const [post, setPost] = useState<BoardPost | undefined>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState<BoardPost>();

  //Set 2 when you firure out chakra-ui masonry
  const columns = useBreakpointValue({ base: 1, lg: 1 });

  const decodeHtmlEntities = (str: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  };

  const [deleteBoardPost, { isLoading: isDeleting }] = useDeleteBoardPostMutation();

  const handleDelete = async (id: string) => {
    try {
      await deleteBoardPost({
        globalId: id,
      }).unwrap;
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };
  const handleUpdate = (post: BoardPost) => {
    setPost(post);
    setOpen((prev) => !prev);
  };

  const handleOpenDialog = () => {
    setPost(undefined);
    setOpen((prev) => !prev);
  };

  const dotsMenuWidth = 50;

  const renderConfirmationModal = () => {
    return (
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} size={'xs'}>
        <ModalContent>
          <ModalHeader>Czy na pewno chcesz usunąć ogłoszenie?</ModalHeader>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setModalOpen(false)}>
              anuluj
            </Button>
            <Button
              colorScheme="purple"
              onClick={() => {
                handleDelete(selectedElement?.globalId || '');
                setModalOpen(false);
              }}
            >
              usuń
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const renderElement = (element: BoardPost) => {
    return (
      <Box
        style={{
          color: 'black',
          fontFamily: 'League Spartan',

          overflow: 'hidden',
          borderRadius: 2,
          background: `url(${boardpostbackground})`,
          backgroundSize: '300px',
          backgroundPosition: 'bottom right',
          backgroundRepeat: 'no-repeat',
          padding: '16px',
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
          <Text
            style={{
              fontWeight: 600,
              fontSize: 24,
              // marginBottom: 1,
              wordBreak: 'break-word',
            }}
          >
            {element.title}
          </Text>
          <Menu>
            <MenuButton
              style={{ background: 'transparent', width: dotsMenuWidth }}
              as={Button}
              rightIcon={<MdMoreVert size={'20'} style={{}} />}
            />
            <MenuList>
              <MenuItem onClick={() => handleUpdate(element)}>Edytuj</MenuItem>
              <MenuItem
                onClick={() => {
                  setModalOpen(true);
                  setSelectedElement(element);
                }}
              >
                Usuń
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>

        <Box
          className="display"
          style={{
            paddingTop: 1,
            paddingLeft: 1,
            paddingRight: 1,
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(element.content) }} />
          <PhotoGallery preview photos={element.files.filter((f: any) => f.type === 'image')} />
          <Files preview files={element.files.filter((f: any) => f.type !== 'image')} />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: 16,
            }}
          >
            <Text style={{ fontSize: 18, marginRight: 8 }}>Dotyczy domen: </Text>
            {element?.schemas?.map((schema: string, index: number) => {
              return (
                <Badge colorScheme="purple" key={index} style={{ margin: 2 }}>
                  {schema}
                </Badge>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {renderConfirmationModal()}
      <Box style={{ marginBottom: 16 }}>
        <Button
          type={'submit'}
          variant={'solid'}
          isLoading={isDeleting}
          colorScheme={'purple'}
          onClick={handleOpenDialog}
        >
          Dodaj ogłoszenie
        </Button>
        {open && <PostDialog open={open} ToggleOpen={handleOpenDialog} data={post} />}
      </Box>
      <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={4}>
        {data?.map((element: BoardPost) => (
          <GridItem key={element.globalId} margin={0} padding={0}>
            <Card key={element.globalId}>{renderElement(element)}</Card>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default BoardPostList;
