import {
  Button,
  Flex,
  HStack,
  List,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Header } from '@/components/ui/Header/Header';
import { Breadcrumbs } from '@/components/ui/Navigation/Breadcrumbs';
import Paper from '@/components/ui/Paper';
import { createRolesHook } from '@/hooks/useRoles';
import { Breadcrumb } from '@/types/Breadcrumbs';

import { useDeleteMealPlanMutation, useGetMealPlansQuery } from '../api';
import { AddMealsPlanModal } from '../components/AddMealsPlanModal';
import { MealPlansListItem } from '../components/MealPlansListItem';
import { MealsPlanDetailsModal } from '../components/MealsPlanDetailsModal';
import { MealPlan } from '../types';
const breadcrumbs: Breadcrumb[] = [
  {
    path: '/app/mealsPlan',
    displayValue: 'Jadłospis',
    isCurrentRoute: true,
  },
];

const useRoles = createRolesHook(['user', 'admin', 'nutritionist']);

export const MealsPlanPage = () => {
  useRoles();
  const [mealsPlan, setMealsPlan] = useState<MealPlan | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const { data: mealPlans, isLoading } = useGetMealPlansQuery();
  const [deleteMealsPlan, { isLoading: isDeleting }] = useDeleteMealPlanMutation();

  const handleDeleteMealsPlan = (mealsPlan: MealPlan) => {
    setMealsPlan(mealsPlan);
    onOpenDelete();
  };

  const handleConfirmDeleteMealsPlan = async () => {
    try {
      if (mealsPlan) {
        await deleteMealsPlan({
          id: mealsPlan?.id,
        }).unwrap();
      }
      setMealsPlan(null);
      onCloseDelete();
    } catch (err: any) {
      toast.error(err.data.message || 'Nie udało się usunąć jadłospisu.');
    }
  };

  const handleDownloadFile = () => {
    window.open(
      'https://4parentsprod.blob.core.windows.net/assets/Szablon_importu_jad%C5%82ospisu.xlsx',
      '__blank'
    );
  };

  const handleSelectMealsPlan = (mealsPlan: MealPlan) => {
    setMealsPlan(mealsPlan);
    onOpenDetails();
  };

  const renderConfirmationModal = () => {
    return (
      <Modal isOpen={isOpenDelete} onClose={onCloseDelete} size={'xs'}>
        <ModalContent>
          <ModalHeader>Czy na pewno chcesz usunąć jadłospis?</ModalHeader>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDelete}>
              anuluj
            </Button>
            <Button
              isLoading={isDeleting}
              colorScheme="purple"
              onClick={handleConfirmDeleteMealsPlan}
            >
              usuń
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Stack>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <HStack pb={4}>
        <Header>Jadłospis</Header>
        <Flex flexGrow={1} />
        {/* <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} /> */}
      </HStack>
      <HStack>
        <Button colorScheme="purple" onClick={onOpen}>
          Dodaj jadłospis
        </Button>
        <Button colorScheme="green" onClick={handleDownloadFile}>
          Pobierz wzór (.xlsx)
        </Button>
      </HStack>
      <AddMealsPlanModal isOpen={isOpen} onClose={onClose} />
      <MealsPlanDetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        mealsPlan={mealsPlan}
        handleDelete={handleDeleteMealsPlan}
      />
      {renderConfirmationModal()}
      <Paper shadow={'lg'} p={4}>
        {isLoading ? (
          <Spinner />
        ) : mealPlans?.length === 0 ? (
          <VStack py={10}>
            <Text color={'GrayText'}>Brak zaimportowanych plików z jadłospisami</Text>
          </VStack>
        ) : (
          <List color={'initial'}>
            {mealPlans?.map((mealsPlan) => (
              <MealPlansListItem
                key={mealsPlan.id}
                mealsPlan={mealsPlan}
                handleSelect={handleSelectMealsPlan}
              />
            ))}
          </List>
        )}
      </Paper>
    </Stack>
  );
};
