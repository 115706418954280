import { Checkbox } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentProps, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormMultiSelect, { Option } from '@/components/form/FormMultiSelect';
import { FormSection } from '@/components/form/FormSection';
import { ModalForm } from '@/components/ui/Modal/ModalForm';
import { useDomainsQuery } from '@/features/domains';
import { FormFilePicker } from '@/features/files';

import { useImportMealPlanMutation } from '../api';

type Props = Omit<ComponentProps<typeof ModalForm>, 'formId' | 'title' | 'children' | 'onSubmit'>;

type Form = {
  file: File | null;
  schemas: Array<Option>;
};

const scheme = yup.object({
  file: yup.mixed().nullable().required('Plik jest wymagany'),
  schemas: yup
    .array()
    .required()
    .test('has-at-least-one-string', 'Wymagana conajmniej jedna domena docelowa', (value: any) => {
      return (
        value && value.some((str: { value: string; label: string }) => str.value?.trim().length > 0)
      );
    }),
});

const defaultValues: Form = {
  file: null,
  schemas: [],
};

export const AddMealsPlanModal = ({ ...props }: Props) => {
  const { data: domains, isLoading: isLoadingPosts } = useDomainsQuery();
  const [selectAll, setSelectAll] = useState(false);

  const [importMealsPlan] = useImportMealPlanMutation();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Form>({
    defaultValues: defaultValues,
    resolver: yupResolver(scheme),
  });

  const onSubmit = async (values: Form) => {
    console.log(values);
    try {
      if (values.file)
        await importMealsPlan({
          files: [values.file],
          schemas: values.schemas.map((schema) => schema.value),
        }).unwrap();
      props.onClose();
    } catch (err: any) {
      toast.error(err.data.message || 'Nie udało się zaimportować jadłospisu.');
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      reset(defaultValues);
    }
  }, [props.isOpen, reset]);

  return (
    <ModalForm
      saveButtonLabel="Dodaj"
      formId={`addMealsPlanForm`}
      title={'Dodawanie jadłospisu'}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
      isSubmitting={isSubmitting}
      {...props}
    >
      <FormSection title="Plik z planem posiłków">
        <FormFilePicker control={control} name={'file'} label="Dodaj plik (.xlsx)" />
      </FormSection>
      <FormSection title="Placówki">
        <FormMultiSelect
          selectAll={selectAll}
          disabled={selectAll}
          errors={errors}
          label="Domeny"
          emptyStateLabel="Brak dostępnych domen"
          control={control}
          name="schemas"
          options={
            domains?.map((domain) => {
              return { label: domain.name, value: domain.domain };
            }) || []
          }
        />
        <Checkbox
          colorScheme={'purple'}
          checked={selectAll}
          onChange={(e) => setSelectAll(e.target.checked)}
          isDisabled={isLoadingPosts}
        >
          Wszystkie domeny
        </Checkbox>
      </FormSection>
    </ModalForm>
  );
};
