import AccountActivatedSuccess from './AccountActivatedSuccess';
import { CreatePasswordPage } from './CreatePasswordPage';
import { ForgetPasswordEmailSentSuccessPage } from './ForgetPasswordEmailSentSuccessPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import LoginPage from './LoginPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { ResetPasswordSuccessPage } from './ResetPasswordSuccessPage';

export const authRoutes = [
  {
    path: '',
    element: <LoginPage />,
  },
  {
    path: 'create-password',
    element: <CreatePasswordPage />,
  },
  {
    path: 'account-activated',
    element: <AccountActivatedSuccess />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: 'password-reset-email-sent',
    element: <ForgetPasswordEmailSentSuccessPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: 'reset-password-success',
    element: <ResetPasswordSuccessPage />,
  },
];
