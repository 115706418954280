import { Flex, HStack, Square, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { MdHome, MdGroups, MdDescription, MdLogout, MdPostAdd, MdFastfood } from 'react-icons/md';

import { CurrentUserAvatar, LogoutButton } from '@/features/auth';

import DrawerMenuItem from './DrawerMenuItem';

export const DRAWER_WIDTH = '360px';

const DrawerMenu = () => {
  return (
    <Stack as={'nav'} w={DRAWER_WIDTH} shadow={'md'} h={`100vh`}>
      <Stack
        direction={'column'}
        h={'100%'}
        overflowY={'auto'}
        bg={'purple.900'}
        color={'white'}
        p={2}
        spacing={4}
      >
        <HStack justify={'center'} p={4}>
          <Square p={2} borderRadius="md" bg="whiteAlpha.100">
            <MdGroups size={32} />
          </Square>
          <Text fontWeight={'bold'} size={'lg'} textAlign={'center'}>
            4P Management
          </Text>
        </HStack>

        {/* <Flex justify={'stretch'}>
          <CurrentUserMenu />
        </Flex> */}
        {/* <Divider /> */}
        <DrawerMenuItem to={'/app'} label={'Start'} icon={<MdHome />} matchString={'/app'} />
        <DrawerMenuItem
          to={'/app/reports'}
          label={'Raporty'}
          icon={<MdDescription />}
          allowedRoles={['admin', 'user']}
        />
        <DrawerMenuItem
          to={'/app/boardPosts'}
          label={'Ogłoszenia'}
          icon={<MdPostAdd />}
          allowedRoles={['admin', 'user']}
        />
        <DrawerMenuItem
          to={'/app/mealsPlan'}
          label={'Jadłospisy'}
          icon={<MdFastfood />}
          allowedRoles={['admin', 'user', 'nutritionist']}
        />
        {/* <DrawerMenuItem label={'Jadłospisy'} icon={<MdDescription />} /> */}
        {/* <DrawerMenuItem to={'/app/users'} label={'Użytkownicy'} icon={<MdPerson />} /> */}
        <Flex flexGrow={1} />
        <CurrentUserAvatar />
        <LogoutButton
          colorScheme={'whiteAlpha'}
          color={'white'}
          variant={'ghost'}
          rightIcon={<MdLogout />}
        >
          Wyloguj
        </LogoutButton>
        <Text fontSize={'xs'} color={'gray.300'} align="center">
          UI v0.1.0
        </Text>
      </Stack>
    </Stack>
  );
};

export default DrawerMenu;
