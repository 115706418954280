import { Button, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CopyrightInfoBox from '@/components/ui/CopyrightInfoBox/CopyrightInfoBox';

import { ReactComponent as HappyIlustration } from '../assets/happy.svg';
import { PublicRoutesCard } from '../components/PublicRoutesCard';

export const ResetPasswordSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Stack
      width="full"
      justify="center"
      align="center"
      maxWidth="730px"
      height={{ md: '100vh', sm: '100%' }}
      maxHeight={{ md: '571px', sm: '100%' }}
      m={'16px'}
    >
      <PublicRoutesCard
        leftImage={<HappyIlustration width={'100%'} height={'250'} />}
        leftTitle="Przypomnienie hasła"
      >
        <Stack spacing={'16px'} mb={4} align={'center'}>
          <Text
            color="#444444"
            fontFamily="League Spartan"
            fontSize={{ base: 'md', md: 'lg' }}
            fontWeight="bold"
            textAlign="center"
          >
            Udało się!
          </Text>
          <Text
            color="#444444"
            fontSize={{ base: 'md', md: 'lg' }}
            textAlign="center"
            fontFamily="League Spartan"
          >
            Hasło zostało zmienione.
          </Text>
          <Button
            backgroundColor={'#8D3F98'}
            width={{ base: '100%', sm: 'fit-content' }}
            borderRadius={22}
            py={'12px'}
            px={'32px'}
            fontFamily={'League Spartan'}
            fontSize={16}
            onClick={() => navigate('/')}
            colorScheme={'purple'}
          >
            Przejdź do logowania
          </Button>
        </Stack>
      </PublicRoutesCard>

      <CopyrightInfoBox />
    </Stack>
  );
};
