import { Divider, HStack, Icon, Text } from '@chakra-ui/react';
import { SiMicrosoftexcel } from 'react-icons/si';

import { MenuListItem } from '@/components/ui/MenuList/MenuListItem';

import { MealPlan } from '../types';

type Props = {
  mealsPlan: MealPlan;
  handleSelect: (mealsPlan: MealPlan) => void;
};

export const MealPlansListItem = ({ mealsPlan, handleSelect }: Props) => {
  return (
    <>
      <MenuListItem
        onClick={() => {
          handleSelect(mealsPlan);
        }}
      >
        <HStack alignItems={'center'}>
          <Icon as={SiMicrosoftexcel} fontSize={'xl'} color={'blackAlpha.700'} />
          <Text>{mealsPlan.importedFile}</Text>
        </HStack>
      </MenuListItem>
      <Divider borderColor="blackAlpha.500" />
    </>
  );
};
