import {
  Badge,
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormInput from '@/components/form/FormInput';
import FormMultiSelect from '@/components/form/FormMultiSelect';
import FormSwitch from '@/components/form/FormSwitch';
import FormTextEditor from '@/components/form/FormTextEditor';
import { useDomainsQuery } from '@/features/domains';
import { FormFileHandler } from '@/features/files';

import { useAddBoardPostMutation, useUpdateBoardPostMutation } from '../api/boardPostsApi';
import { BoardPost } from '../types/BoardPost';

const PostDialog = ({
  data,
  open,
  ToggleOpen,
}: {
  data?: BoardPost;
  open: boolean;
  ToggleOpen: () => void;
}) => {
  type Form = {
    title: string;
    content: string;
    pinned: boolean;
    files: Array<any>;
    schemas: Array<string>;
  };

  const [selectAll, setSelectAll] = useState(false);
  const [isProcessingFiles, setProcessingFiles] = useState(false);

  const preparedDomains = data?.schemas.map((schema, index) => {
    return (
      <Badge colorScheme="purple" key={index} style={{ margin: 2 }}>
        {schema}
      </Badge>
    );
  });

  const defaultValues = {
    title: '',
    content: '',
    pinned: false,
    files: [],
    schemas: [],
  };

  const scheme = yup.object({
    title: yup.string().required('Pole wymagane'),
    content: yup
      .string()
      .test('is-html-entities-decoded', 'Pole wymagane', (value) => {
        if (!value) {
          return true;
        }
        try {
          // const decodedValue = decodeHTMLEntities(value);
          const cleanText = value.replace(/<\/?[^>]+(>|$)/g, '');

          return cleanText.length > 1;
        } catch (err) {
          return false;
        }
      })
      .required('Pole wymagane'),
    pinned: yup.boolean().required('Pole wymagane'),
    files: yup.array().of(yup.mixed().nullable()),
    schemas: yup
      .array()
      .required()
      .test(
        'has-at-least-one-string',
        'Wymagana conajmniej jedna domena docelowa',
        (value: any) => {
          return (
            value &&
            value.some((str: { value: string; label: string }) => str.value?.trim().length > 0)
          );
        }
      ),
  });

  const updateSchema = yup.object({
    title: yup.string().required('Pole wymagane'),
    content: yup
      .string()
      .test('is-html-entities-decoded', 'Pole wymagane', (value) => {
        if (!value) {
          return true;
        }
        try {
          // const decodedValue = decodeHTMLEntities(value);
          const cleanText = value.replace(/<\/?[^>]+(>|$)/g, '');

          return cleanText.length > 1;
        } catch (err) {
          return false;
        }
      })
      .required('Pole wymagane'),
    pinned: yup.boolean().required('Pole wymagane'),
    files: yup.array().of(yup.mixed().nullable()),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: data || defaultValues,
    resolver: yupResolver(data ? updateSchema : scheme),
  });

  const [createBoardPost] = useAddBoardPostMutation();
  const [updateBoardPost] = useUpdateBoardPostMutation();
  const { data: domains, isLoading: isLoadingPosts } = useDomainsQuery();

  const onSubmit = async (values: Form) => {
    const preparedData = {
      globalId: data?.globalId || '',
      title: values.title,
      content: values.content,
      pinned: values.pinned,
      schemas: values.schemas.map((schema: any) => schema.value),
      files: values.files.map((file) => file.globalId),
    };

    try {
      if (data) {
        await updateBoardPost(preparedData).unwrap();
      } else {
        await createBoardPost(preparedData).unwrap();
      }
      toast.info(data ? 'Zaktualizowano ogłoszenie' : 'Dodano ogłoszenie');
      ToggleOpen();
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <>
      <Modal isOpen={open} onClose={ToggleOpen} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dodaj ogłoszenie</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)} id="addPostForm">
              <Box style={{ marginBottom: 16 }}>
                <FormInput
                  error={errors.title}
                  placeholder="Tytuł ogłoszenia"
                  {...register('title')}
                  type={'text'}
                />
              </Box>
              <FormTextEditor control={control} name={'content'} />
              <Box>
                <FormFileHandler
                  control={control}
                  name={'files'}
                  setProcessing={setProcessingFiles}
                />
              </Box>

              {!data ? (
                <Box style={{ marginTop: 16 }}>
                  <FormMultiSelect
                    selectAll={selectAll}
                    disabled={selectAll}
                    errors={errors}
                    label="Domeny"
                    emptyStateLabel="Brak dostępnych domen"
                    control={control}
                    name="schemas"
                    options={
                      domains?.map((domain) => {
                        return { label: domain.name, value: domain.domain };
                      }) || []
                    }
                  />
                  <Box style={{ marginTop: 16, display: 'flex', justifyContent: 'end' }}>
                    <Checkbox
                      colorScheme={'purple'}
                      checked={selectAll}
                      onChange={(e) => setSelectAll(e.target.checked)}
                      isDisabled={isLoadingPosts}
                    >
                      Wszystkie domeny
                    </Checkbox>
                  </Box>
                </Box>
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginTop: 16,
                  }}
                >
                  <Text style={{ fontSize: 18 }}>Dotyczy domen: </Text> <Box>{preparedDomains}</Box>
                </Box>
              )}
              <Box style={{ marginTop: 16 }}>
                <FormSwitch control={control} name="pinned" label="Przypnij ogłoszenie" />
              </Box>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={ToggleOpen}
              isLoading={isSubmitting || isProcessingFiles}
            >
              Anuluj
            </Button>
            <Button
              form="addPostForm"
              type={'submit'}
              variant={'solid'}
              isLoading={isSubmitting || isProcessingFiles}
              colorScheme={'purple'}
            >
              {!data ? 'Dodaj' : 'Zapisz'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PostDialog;
