import { baseApi } from '@/lib';

import { GetMealPlansRequest, GetMealPlansResponse, ImportMealPlanRequest } from '../types';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['MealPlans'],
});

export const mealsPlanApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getMealPlans: builder.query<GetMealPlansResponse, GetMealPlansRequest>({
      query: () => '/dailyMenus',
      providesTags: ['MealPlans'],
    }),
    importMealPlan: builder.mutation<void, ImportMealPlanRequest>({
      query: (data) => {
        const dataForm = new FormData();
        data.schemas.forEach((schema) => {
          dataForm.append('schemas[]', schema);
        });
        dataForm.append('files[]', data.files[0]);
        return {
          url: '/dailyMenus',
          method: 'POST',
          body: dataForm,
        };
      },
      invalidatesTags: ['MealPlans'],
    }),
    deleteMealPlan: builder.mutation<void, { id: string }>({
      query: (data) => ({
        url: `/dailyMenus/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MealPlans'],
    }),
  }),
});

export const { useGetMealPlansQuery, useImportMealPlanMutation, useDeleteMealPlanMutation } =
  mealsPlanApi;
