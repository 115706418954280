import {
  Badge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import { MealPlan } from '../types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mealsPlan: MealPlan | null;
  handleDelete: (mealsPlan: MealPlan) => void;
};

export const MealsPlanDetailsModal = ({ isOpen, onClose, mealsPlan, handleDelete }: Props) => {
  if (!mealsPlan) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{mealsPlan.importedFile}</ModalHeader>
        <ModalBody>
          <VStack>
            <Box width={'100%'}>
              <Text style={{ fontSize: 18, marginRight: 8 }}>Dotyczy domen: </Text>
              {mealsPlan?.schemas.map((schema: string, index: number) => {
                return (
                  <Badge colorScheme="purple" key={index} style={{ margin: 2 }}>
                    {schema}
                  </Badge>
                );
              })}
            </Box>
            <Box width={'100%'}>
              <Text style={{ fontSize: 18, marginRight: 8 }}>Dotyczy dat: </Text>
              {mealsPlan?.importedDates.map((schema: string, index: number) => {
                return (
                  <Badge colorScheme="purple" key={index} style={{ margin: 2 }}>
                    {schema}
                  </Badge>
                );
              })}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter gap={2} justifyContent={'space-between'}>
          <Button
            form="addPostForm"
            variant={'solid'}
            colorScheme={'orange'}
            onClick={() => handleDelete(mealsPlan)}
          >
            Usuń
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Zamknij
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
