import { baseApi } from '@/lib';

import { FileUploadResponse } from '../types';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['Files'],
});

export const uploadFilesApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileUploadResponse, FormData>({
      query: (data) => ({
        url: `files`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useUploadFileMutation } = uploadFilesApi;
